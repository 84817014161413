import React, { FC, useRef } from 'react';
import s from './index.module.scss';

type InputTextProps = {
	placeholder?: string;
	value: string;
	setValue: (value: string) => void;
};

const InputText: FC<InputTextProps> = ({ placeholder = '', value, setValue }: InputTextProps) => {
	const ref = useRef<HTMLInputElement>(null);

	const setFocus = (): void => {
		if (ref && ref.current) {
			ref.current.focus();
		}
	};

	return (
		<div className={s.input_text}>
			<input
				type="text"
				placeholder={placeholder}
				ref={ref}
				onChange={e => setValue(e.target.value)}
				value={value || ''}
			/>
			{value && <div className={s.input_text_edit} onClick={setFocus} />}
		</div>
	);
};

export default InputText;

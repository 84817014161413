import React, { FC, useState } from 'react';
import { useFloating, flip, shift } from '@floating-ui/react-dom';
import { useClickAway } from 'react-use';
import cx from 'classnames';

// components
import StoreItemPopup from '../../../StorePopups/StoreItemPopup';
import StoreCouponsPopup from '../../../StorePopups/StoreCouponsPopup';

// icons
import { ReactComponent as DeleteSvg } from 'assets/image/icons/trash-new.svg';
import { ReactComponent as ThreeDotsSvg } from 'assets/image/icons/menu.svg';
import { ReactComponent as EditSvg } from 'assets/image/icons/edit-new.svg';
import { ReactComponent as CouponSvg } from 'assets/image/icons/coupone.svg';

// types
import { IStoreItemResponse } from '@joc/api-gateway';
import { StoreItemResponseType } from '../../../types';

// hooks
import { useDeleteStoreItem } from 'hooks/store/items/useDeleteStoreItem';

// styles
import styles from './StoreTableActions.module.scss';

type StoreItemActionsTypes = {
	storeItem: IStoreItemResponse;
};

const StoreItemActions: FC<StoreItemActionsTypes> = ({ storeItem }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [popupEditOpen, setPopupEditOpen] = useState(false);
	const [popupManageCouponsOpen, setPopupManageCouponsOpen] = useState(false);
	const { deleteStoreItem } = useDeleteStoreItem();

	const { refs, floatingStyles } = useFloating({
		placement: 'bottom-end',
		middleware: [flip(), shift()],
	});

	useClickAway(refs.floating, () => setIsOpen(false));

	const togglePopupHandler = () => {
		setIsOpen(false);
		setPopupEditOpen(!popupEditOpen);
	};

	const togglePopupManageCouponsHandler = () => {
		setIsOpen(false);
		setPopupManageCouponsOpen(!popupManageCouponsOpen);
	};

	const handleDeleteStoreItem = () => {
		setIsOpen(false);
		deleteStoreItem(storeItem.id);
	};

	return (
		<>
			{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
			<button
				ref={refs.setReference}
				className={cx(styles.menuButton, { [styles.menuButton_active]: isOpen })}
				onClick={() => setIsOpen(!isOpen)}
			>
				<ThreeDotsSvg />
			</button>

			{isOpen && (
				<div ref={refs.setFloating} style={floatingStyles} className={styles.menuDropdown}>
					<div className={styles.menuItem} onClick={togglePopupHandler}>
						<EditSvg title="Edit" />
						<span>Edit</span>
					</div>
					<div className={styles.menuItem} onClick={handleDeleteStoreItem}>
						<DeleteSvg title="Delete" />
						<span>Delete</span>
					</div>
					<div className={styles.menuItem} onClick={togglePopupManageCouponsHandler}>
						<CouponSvg title="Manage coupons" />
						<span>Manage coupons</span>
					</div>
				</div>
			)}

			{popupEditOpen && (
				<StoreItemPopup
					open={popupEditOpen}
					storeItem={storeItem as unknown as StoreItemResponseType}
					onClose={togglePopupHandler}
				/>
			)}
			{popupManageCouponsOpen && (
				<StoreCouponsPopup
					open={popupManageCouponsOpen}
					storeItem={storeItem}
					onClose={togglePopupManageCouponsHandler}
				/>
			)}
		</>
	);
};

export default StoreItemActions;

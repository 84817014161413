import { useMutation, useQueryClient } from '@tanstack/react-query';
import { StoreItemResponse } from '@joc/api-gateway';
import { API } from '../../../core/API';
import { STORE_ITEMS_QUERY_KEY } from '../../../core/constants';

interface IUsePublishStoreItem {
	// eslint-disable-next-line no-unused-vars
	publishStoreItem: ({ id }: { id: number }) => void;
	isError: boolean;
	isSuccess: boolean;
	data?: StoreItemResponse;
}

const publishStoreItemApi = async (id: number) => {
	return API.publishItem(id);
};

export const usePublishStoreItem = (): IUsePublishStoreItem => {
	const queryClient = useQueryClient();
	const {
		mutate: publishStoreItem,
		data,
		isError,
		isSuccess,
	} = useMutation<StoreItemResponse, Error, { id: number }>({
		mutationFn: ({ id }) => publishStoreItemApi(id),
		onSettled: () => queryClient.invalidateQueries({ queryKey: [STORE_ITEMS_QUERY_KEY] }),
	});

	return { publishStoreItem, data, isError, isSuccess };
};

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API } from '../../../core/API';
import { STORE_COUPONS_QUERY_KEY, STORE_ITEMS_QUERY_KEY } from '../../../core/constants';

interface IUseDeleteCoupon {
	// eslint-disable-next-line no-unused-vars
	deleteCoupon: (id: number) => void;
}

const deleteCouponApi = async (id: number) => {
	return API.deleteStoreCoupon(id);
};

export const useDeleteCoupon = (): IUseDeleteCoupon => {
	const queryClient = useQueryClient();
	const { mutate: deleteCoupon } = useMutation<void, Error, number>({
		mutationFn: storeItem => deleteCouponApi(storeItem),
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: [STORE_COUPONS_QUERY_KEY] });
			queryClient.invalidateQueries({ queryKey: [STORE_ITEMS_QUERY_KEY] });
		},
	});

	return { deleteCoupon };
};

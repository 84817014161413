import React, { useEffect, useState } from 'react';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { getDailyQuotes } from 'redux/dailyQuotes-service/actions';
import { Store } from '../../../../redux/root';
// components
import InputSearch from '../../Inputs/InputSearch';
import ButtonDefault from '../../Buttons/ButtonsDefault';
import { AddQuoteModal } from './AddQuoteModal';
import { DailyQuotesTable } from './DailyQuotesTable';
// images
import { ReactComponent as Plus } from 'assets/image/plus.svg';
import { ReactComponent as NoQuotesImage } from 'assets/image/no-items-image.svg';
// styles
import styles from './DailyQuotes.module.scss';

const DailyQuotes = () => {
	const dispatch = useDispatch();

	const [isModalOpen, setIsModalOpen] = useState(false);

	const dailyQuotesRecords = useSelector((store: Store) => store.quotesRedux.quotesRecords);

	const handleModalClose = () => setIsModalOpen(false);

	const handleCreateDailyQuoteCreate = () => {
		setIsModalOpen(true);
	};

	const dailyQuoteSearchHandler = (value: string) => {
		dispatch(getDailyQuotes(value.trim()));
	};

	useEffect(() => {
		return () => {
			dispatch(getDailyQuotes(''));
		};
	}, []);

	return (
		<div className={styles.pageContainer}>
			<div className={styles.controlsContainer}>
				<InputSearch placeholder="Search..." classList={['big']} changeHandler={dailyQuoteSearchHandler} />

				<ButtonDefault
					title="Create"
					classList={['primary', 'sm']}
					clickHandler={handleCreateDailyQuoteCreate}
					icon={<Plus style={{ width: '16px', height: '16px' }} />}
				/>
			</div>
			{dailyQuotesRecords?.length ? (
				<DailyQuotesTable />
			) : (
				<div className={styles.noItemsContainer}>
					<NoQuotesImage className={styles.noItemsImage} />
					<div className={styles.noItemsTextContainer}>
						<span className={styles.noItemsTitle}>Oops</span>
						<span className={styles.noItemsText}>
							There are currently no daily quotes in the list. Create them to see them here.
						</span>
					</div>
				</div>
			)}

			<AddQuoteModal isModalOpen={isModalOpen} onClose={handleModalClose} />
		</div>
	);
};

export default DailyQuotes;

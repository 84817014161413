import { useMutation, useQueryClient } from '@tanstack/react-query';
import { StoreCouponRequest } from '@joc/api-gateway';
import { API } from '../../../core/API';
import { STORE_COUPONS_QUERY_KEY, STORE_ITEMS_QUERY_KEY } from '../../../core/constants';
import { CreateStoreCouponsRequesе } from '@joc/api-gateway/lib/api-client';

interface IUseCreateCoupons {
	// eslint-disable-next-line no-unused-vars
	createCoupons: ({ storeItemId, coupons }: { storeItemId: number; coupons: Array<StoreCouponRequest> }) => void;
	isSuccess: boolean;
	isPending: boolean;
	isError: boolean;
}

const createNewCoupons = async (storeItemId: number, coupons: Array<StoreCouponRequest>) => {
	return API.createStoreCoupons(storeItemId, CreateStoreCouponsRequesе.fromJS({ couponCodes: coupons }));
};

export const useCreateCoupons = (): IUseCreateCoupons => {
	const queryClient = useQueryClient();
	const {
		mutate: createCoupons,
		isSuccess,
		isPending,
		isError,
	} = useMutation<void, Error, { storeItemId: number; coupons: Array<StoreCouponRequest> }>({
		mutationFn: ({ storeItemId, coupons }) => createNewCoupons(storeItemId, coupons),
		onSettled: () => {
			queryClient.invalidateQueries({ queryKey: [STORE_COUPONS_QUERY_KEY] });
			queryClient.invalidateQueries({ queryKey: [STORE_ITEMS_QUERY_KEY] });
		},
	});

	return { createCoupons, isSuccess, isPending, isError };
};

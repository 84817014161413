import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';

// components
import StoreAddCoupons from './StoreAddCoupons';
import { StoreCouponsTable } from '../../StoreTables';
import Dialog from 'shared/components/Dialog';
import ButtonCross from 'shared/components/Buttons/ButtonCross';
import { SnackbarSuccess } from 'shared/components/_Snackbars';

// types, enums
import StoreCouponsTabs, { CouponsTabs } from './StoreCouponsTabs';
import { IStoreItemResponse } from '@joc/api-gateway';

// styles
import styles from '../StoreItemPopup/StoreItemPopup.module.scss';

type StoreItemCouponsPopupProps = {
	open: boolean;
	storeItem: IStoreItemResponse;
	onClose?: () => void;
};

const StoreItemCouponsPopup: FC<StoreItemCouponsPopupProps> = ({ open = true, storeItem, onClose }) => {
	const [currentTab, setCurrentTab] = useState<CouponsTabs>(CouponsTabs.AddCoupons);
	const [isSuccessSnackbar, setIsSuccessSnackbar] = useState(false);
	const [isSingle, setIsSingle] = useState(false);

	const closePopupClickHandler = useCallback(() => {
		onClose?.();
	}, []);

	const couponsSuccessUploadHandler = useCallback((isSingleCoupon: boolean) => {
		setIsSingle(isSingleCoupon);
		setIsSuccessSnackbar(true);
		if (!isSingleCoupon) {
			setCurrentTab(CouponsTabs.CouponsList);
		}
	}, []);

	const ActiveManageCouponsTab = useMemo(() => {
		switch (currentTab) {
			case CouponsTabs.CouponsList:
				return <StoreCouponsTable storeItemId={storeItem.id} />;
			case CouponsTabs.AddCoupons:
				return (
					<StoreAddCoupons
						storeItemId={storeItem.id}
						onClose={onClose}
						onCouponsUploaded={couponsSuccessUploadHandler}
					/>
				);
			default:
				return <></>;
		}
	}, [currentTab]);

	useEffect(() => {
		setCurrentTab(storeItem.quantity ? CouponsTabs.CouponsList : CouponsTabs.AddCoupons);
	}, []);

	return (
		<Dialog open={open} maxWidth="xl" isFullScreen={true}>
			<section className={styles.storeItemPopup}>
				<span className={styles.storeItemPopup__title}>Manage coupons</span>
				<ButtonCross clickHandler={closePopupClickHandler} parentClassName={styles.storeItemPopup__close} />
				<StoreCouponsTabs currentTab={currentTab} setCurrentTab={setCurrentTab} />
				{ActiveManageCouponsTab}
				<SnackbarSuccess
					open={isSuccessSnackbar}
					onClose={setIsSuccessSnackbar}
					message={
						isSingle
							? 'The coupon has been created successfully!'
							: 'Coupons have been created successfully!'
					}
					width={308}
				/>
			</section>
		</Dialog>
	);
};

export default StoreItemCouponsPopup;

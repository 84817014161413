import React, { FC, useEffect } from 'react';
import { Field, FieldProps, Form, useFormikContext } from 'formik';
import cx from 'classnames';

// components
import InputText from 'shared/components/Inputs/InputText';
import InputErrorHint from 'shared/components/InputErrorHint';
import Loader from 'shared/components/Loader';
import ButtonDefault from 'shared/components/Buttons/ButtonsDefault';

// types
import { ICreateStoreCouponsRequesе } from '@joc/api-gateway/lib/api-client';
import { StoreItemFormType } from '../../types';

// styles
import styles from './StoreCouponForm.module.scss';

type StoreCouponFormProps = {
	isSaving: boolean;
	isFinishedSaving: boolean;
	onClose?: () => void;
};

const StoreCouponForm: FC<StoreCouponFormProps> = ({ isSaving, isFinishedSaving, onClose }) => {
	const { setFieldValue, setFieldTouched, dirty, isValid } = useFormikContext<ICreateStoreCouponsRequesе>();

	const handleChangeCoupon = (value: string) => {
		setFieldTouched('couponCode', true, true).finally();
		setFieldValue('couponCode', value.replace(/\s/g, '').trim()).finally();
	};

	const handleCancel = () => onClose?.();

	useEffect(() => {
		setFieldTouched('couponCode', false, true).finally();
		setFieldValue('couponCode', '');
	}, [isFinishedSaving]);

	return (
		<Form className={styles.storeCouponForm}>
			<Field name="couponCode">
				{({ field, meta }: FieldProps<string, StoreItemFormType>) => (
					<div
						className={styles.storeCouponForm__item}
						onBlur={() => setFieldTouched('couponCode', true, true)}
					>
						<label className={styles.storeCouponForm__item_label} htmlFor="title">
							Coupon code text<span className={styles.storeCouponForm__item_required}>*</span>
						</label>
						<InputText placeholder="Coupon code" setValue={handleChangeCoupon} value={field.value} />
						{meta.error && meta.touched && <InputErrorHint errorText={meta.error} disableMargin={true} />}
					</div>
				)}
			</Field>
			<div className={styles.storeCouponForm__actions}>
				<ButtonDefault
					classList={['secondary', 'lg']}
					parentClassName={styles.storeCouponForm__actions_button}
					title="Cancel"
					clickHandler={handleCancel}
				/>
				<div className={styles.storeCouponForm__actions_button}>
					{isSaving ? (
						<Loader
							loadProps={{
								disableCenterStyle: true,
								parentClassName: styles.storeCouponForm__actions_button_loader,
							}}
						/>
					) : (
						<ButtonDefault
							submitType={true}
							classList={['primary', 'lg']}
							parentClassName={cx(styles.storeCouponForm__actions_button, {
								[styles.disable_submit]: !dirty || !isValid,
							})}
							title="Create"
							disabled={!dirty || !isValid}
						/>
					)}
				</div>
			</div>
		</Form>
	);
};

export default StoreCouponForm;

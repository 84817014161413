import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { ValidationError } from 'yup';

// icons
import { ReactComponent as UploadSVG } from 'assets/image/upload-xlsx.svg';

// helpers
import { parseCouponsXlsx, singleCouponValidationSchema } from '../../../helpers';

// types
import { UploadedCoupon } from '../../../types';

// styles
import styles from './UploadCouponsField.module.scss';

type UploadCouponsFieldProps = {
	// eslint-disable-next-line no-unused-vars
	handleInput: (coupons: Array<UploadedCoupon>) => void;
	setErrorsCount: React.Dispatch<React.SetStateAction<number>>;
};

export const UploadCouponsField: FC<UploadCouponsFieldProps> = ({ handleInput, setErrorsCount }) => {
	const inputRef = React.useRef<HTMLInputElement>(null);
	const [file, setFile] = useState<File>();

	const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
		e.preventDefault();
		if (e.target.files?.length) {
			setFile(Array.from(e.target.files)[0]);
		}
	};

	const handleInputUpload = () => inputRef?.current?.click();

	const handleDrop = (e: Array<File>) => {
		if (e.length) {
			setFile(e[0]);
		}
	};

	useEffect(() => {
		(async () => {
			if (file) {
				const parsedQuotes = await parseCouponsXlsx(file);
				let errors = 0;

				const validatedQuotes = await Promise.all(
					parsedQuotes.map(async quote => {
						try {
							return (await singleCouponValidationSchema.validate(quote, {
								abortEarly: false,
							})) as UploadedCoupon;
						} catch (error) {
							if (error instanceof ValidationError) {
								errors += 1;
								const validationErrors: Record<string, string> = {};
								error.inner.forEach((error: any) => {
									if (error.path !== undefined) {
										validationErrors[error.path] = error.errors[0];
									}
								});

								return { ...quote, errors: validationErrors } as UploadedCoupon;
							}
							return quote as UploadedCoupon;
						}
					})
				);

				setErrorsCount(errors);

				handleInput(validatedQuotes);
			}
		})();
	}, [file]);

	return (
		<div className={styles.wrap}>
			<Dropzone
				accept="text/csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
				multiple={false}
				onDrop={handleDrop}
			>
				{({ getRootProps }) => {
					return (
						<div className={styles.input} {...getRootProps()}>
							<UploadSVG className={styles.uploadIcon} onClick={handleInputUpload} />
							<input
								ref={inputRef}
								id="file-upload"
								type="file"
								accept=".csv, application/vnd.oasis.opendocument.spreadsheet, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
								onInput={handleUpload}
								hidden={true}
								// @ts-ignore
								// eslint-disable-next-line no-return-assign
								onClick={e => (e.target.value = null)}
							/>
						</div>
					);
				}}
			</Dropzone>
		</div>
	);
};

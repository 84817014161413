import React, { ChangeEvent, FC, useEffect } from 'react';
import { Field, FieldProps, useField } from 'formik';
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext } from '@dnd-kit/sortable';

// images
import { ReactComponent as UploadIcon } from 'assets/image/upload-lg.svg';

// components
import ImageItem from './ImageItem';

// constants
import { defaultPhotoTypes } from '../../../components/Organization/Placeholders/EditPlaceholderDialog/helpers';

import styles from './StoreImageField.module.scss';
import InputErrorHint from '../InputErrorHint';

const DEFAULT_IMAGES_COUNT = 12;

type SortableImagesListFieldProps = {
	fieldName: string;
};

export interface IImageItem {
	src: string;
	id: string;
	file?: File;
}

export const prepareImages = (images: Array<string> = []): Array<IImageItem> =>
	images.map((image, index) => ({
		id: `${image}-${index}`,
		src: image,
	}));

const SortableImagesListField: FC<SortableImagesListFieldProps> = ({ fieldName }) => {
	const [field, , helpers] = useField<Array<IImageItem>>(fieldName);

	const inputRef = React.useRef<HTMLInputElement>(null);
	const sensors = useSensors(useSensor(PointerSensor));

	const selectPhotoHandler = () => inputRef?.current?.click();
	const changePhotoHandler = async (event: ChangeEvent<HTMLInputElement>) => {
		event.preventDefault();
		helpers.setTouched(true).finally();
		const { target } = event;

		if (target.files) {
			const uploadedImagesCount = field.value.length;
			const remainingSlots = DEFAULT_IMAGES_COUNT - uploadedImagesCount;

			const files = Array.from(target.files).slice(0, remainingSlots);

			if (files.length > 0) {
				const preparedImages = files.map((image, index) => ({
					file: image,
					src: URL.createObjectURL(image),
					id: `${image.name}-${uploadedImagesCount + index}`,
				}));

				helpers.setValue([...field.value, ...preparedImages]).finally();
			}
		}

		event.target.value = '';
	};

	const handleDragEnd = ({ active, over }: DragEndEvent): void => {
		if (active.id !== over?.id) {
			const oldIndex = field.value.findIndex(item => item.id === active.id);
			const newIndex = field.value.findIndex(item => item.id === over?.id);
			helpers.setValue(arrayMove(field.value, oldIndex, newIndex)).finally();
		}
	};

	const handleRemove = (id: string) => {
		helpers.setTouched(true).finally();
		helpers.setValue(field.value.filter(image => image.id !== id));
	};

	useEffect(() => {
		const makeFieldTouched = () => helpers.setTouched(true).finally();
		inputRef.current?.addEventListener('cancel', makeFieldTouched);

		return () => {
			removeEventListener('cancel', makeFieldTouched);
			field.value.forEach(image => image && URL.revokeObjectURL(image.src));
		};
	}, []);

	return (
		<Field name={fieldName}>
			{({ meta }: FieldProps) => (
				<div className={styles.storeImageField} id="input-container">
					<div className={styles.storeImageField__head}>
						<label className={styles.storeImageField__head_label}>
							Image<span className={styles.storeImageField__head_label_required}>*</span>
						</label>
						<span className={styles.storeImageField__head_subtitle}>
							The first photo on the left will appear as the cover. You can rearrange the photos by
							dragging them.
						</span>
					</div>
					<DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
						<SortableContext items={field.value.map(item => item.id)}>
							<div className={styles.storeImageField__images}>
								{field.value.map(item => (
									<ImageItem key={item.id} id={item.id} src={item.src} onRemove={handleRemove} />
								))}
								{field.value.length < DEFAULT_IMAGES_COUNT ? (
									<UploadIcon
										className={styles.storeImageField__images_upload}
										onClick={selectPhotoHandler}
									/>
								) : (
									<></>
								)}
							</div>
						</SortableContext>
					</DndContext>
					{meta.error && meta.touched && <InputErrorHint errorText={meta.error} disableMargin={true} />}

					<input
						ref={inputRef}
						id="imagePath"
						type="file"
						accept={defaultPhotoTypes}
						hidden={true}
						multiple={true}
						max={12}
						onChange={changePhotoHandler}
					/>
				</div>
			)}
		</Field>
	);
};

export default SortableImagesListField;

import React, { FC, useCallback, useEffect, useState } from 'react';
import moment from 'moment-mini';
import cx from 'classnames';
import { useQueryClient } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';

// components
import StoreItemActions from './StoreTableActions';
import SortableHeaderItem from './SortableHeaderItem';
import ImageCell from 'shared/components/Table/CellRenderers/ImageCell';
import CustomTooltip from 'shared/components/Tooltip';
import Loader from 'shared/components/Loader';
import { SnackbarError, SnackbarSuccess } from 'shared/components/_Snackbars';

// hooks
import { useStoreItems } from 'hooks/store';
import { usePublishStoreItem } from 'hooks/store/items/usePublishStoreItem';

// icons
import { ReactComponent as ClockSvg } from 'assets/image/clock-challenges.svg';
import { ReactComponent as CopySvg } from 'assets/image/icons/copy-link.svg';
import { ReactComponent as PlaySVG } from 'assets/image/close-eye.svg';
import { ReactComponent as PauseSVG } from 'assets/image/open-eye.svg';
import { ReactComponent as NoStatusImage } from 'assets/image/no-items-image.svg';

// types
import { STORE_ITEM_POSSIBLE_SORT, StoreItemSort, StoreItemResponse } from '@joc/api-gateway/lib/api-client';
import { IStoreItemResponse, SortDirection } from '@joc/api-gateway';

// styles
import styles from './StoreTable.module.scss';

type StoreTableProps = {
	search: string;
};

const StoreItemsTable: FC<StoreTableProps> = ({ search }) => {
	const queryClient = useQueryClient();
	const [isItemPublished, setIsItemPublished] = useState(false);
	const [isItemHidden, setIsItemHidden] = useState(false);
	const [isMissingCoupons, setIsMissingCoupons] = useState(false);
	const [isItemExpired, setIsItemExpired] = useState(false);
	const [isApiError, setIsApiError] = useState(false);

	const [totalFetchedStatuses, setTotalFetchedStatuses] = useState(0);
	const [sort, setSort] = useState<StoreItemSort>();
	const {
		data,
		isError: isFetchStoreItemsError,
		isFetching,
		isFetched,
		status,
		fetchNextPage,
		hasNextPage,
		isFetchingNextPage,
	} = useStoreItems(search, sort);

	const {
		publishStoreItem,
		data: storeItemPublishResponse,
		isError: isPublishError,
		isSuccess: isPublishSuccess,
	} = usePublishStoreItem();

	const { ref, inView } = useInView({
		threshold: 1.0, // Trigger only when the element is fully in view
	});

	const handlePublishStoreItem = useCallback(async (storeItem: IStoreItemResponse) => {
		try {
			const itemIsExpired = moment(storeItem.expirationDate).isBefore(moment());
			if (storeItem.quantity && !itemIsExpired) {
				publishStoreItem({ id: storeItem.id });
			} else if (itemIsExpired) setIsItemExpired(true);
			else if (!storeItem.quantity) setIsMissingCoupons(true);
		} catch (err) {
			// eslint-disable-next-line no-console
			console.error(err);
		}
	}, []);

	useEffect(() => {
		if (storeItemPublishResponse?.isPublished && isPublishSuccess) setIsItemPublished(true);
		if (!storeItemPublishResponse?.isPublished && isPublishSuccess) setIsItemHidden(true);

		if (isPublishError || isFetchStoreItemsError) setIsApiError(true);
	}, [storeItemPublishResponse, isPublishSuccess, isPublishError, isFetchStoreItemsError]);

	useEffect(() => {
		if (inView && hasNextPage) {
			fetchNextPage().finally();
		}
	}, [inView, hasNextPage, fetchNextPage]);

	useEffect(() => {
		setTotalFetchedStatuses(
			([] as Array<StoreItemResponse>).concat(...(data?.pages.map(page => page?.records) || [])).length
		);
	}, [data]);

	useEffect(() => {
		return () => {
			queryClient.resetQueries().finally();
		};
	}, []);

	const handleSortChange = useCallback((sortBy: STORE_ITEM_POSSIBLE_SORT, clickedDirection?: SortDirection) => {
		setSort(prevSort => {
			// Якщо вже вибраний той самий sortBy і вказано той самий direction → скидаємо сортування
			if (prevSort?.sortBy === sortBy && clickedDirection && prevSort?.sortDir === clickedDirection) {
				return undefined;
			}

			// Якщо sortBy той самий, але direction не вказано → Використовуємо стару логіку
			if (prevSort?.sortBy === sortBy && !clickedDirection) {
				if (prevSort.sortDir === SortDirection.ASC) {
					return StoreItemSort.fromJS({ ...prevSort, sortDir: SortDirection.DESC });
				}
				return undefined;
			}

			// Якщо вказано clickedDirection, встановлюємо його. Інакше вибираємо ASC за замовчуванням
			return StoreItemSort.fromJS({
				sortBy,
				sortDir: clickedDirection ?? SortDirection.ASC,
			});
		});
	}, []);

	return (
		<section
			className={cx(styles.storeTable_wrapper, {
				[styles.overflow__hidden]: status === 'error' || (isFetched && !totalFetchedStatuses),
				[styles.overflow_y__hidden]: status === 'pending' && !totalFetchedStatuses,
			})}
		>
			<table className={styles.storeTable}>
				<thead className={styles.storeTable_thead}>
					<tr>
						<th align="left" className={styles.storeTable_thead_photo}>
							Photo
						</th>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<th align="left" className={styles.storeTable_thead_title}>
							<SortableHeaderItem
								sortBy={STORE_ITEM_POSSIBLE_SORT.Title}
								title="Title"
								handleSortChange={handleSortChange}
								sort={sort}
							/>
						</th>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<th align="left" className={styles.storeTable_thead_price}>
							<SortableHeaderItem
								sortBy={STORE_ITEM_POSSIBLE_SORT.Price}
								title="Price in coins"
								handleSortChange={handleSortChange}
								sort={sort}
							/>
						</th>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<th align="left" className={styles.storeTable_thead_createdDate}>
							<SortableHeaderItem
								sortBy={STORE_ITEM_POSSIBLE_SORT.CreateDate}
								title="Created Date"
								handleSortChange={handleSortChange}
								sort={sort}
							/>
						</th>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<th align="left" className={styles.storeTable_thead_expirationTime}>
							<SortableHeaderItem
								sortBy={STORE_ITEM_POSSIBLE_SORT.ExpirationDate}
								title="Expiration Time"
								handleSortChange={handleSortChange}
								sort={sort}
							/>
						</th>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<th align="left" className={styles.storeTable_thead_couponsLeft}>
							<SortableHeaderItem
								sortBy={STORE_ITEM_POSSIBLE_SORT.Quantity}
								title="Coupons Left"
								handleSortChange={handleSortChange}
								sort={sort}
							/>
						</th>
						<th align="left" className={styles.storeTable_thead_websiteLink}>
							Website Link
						</th>
						{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
						<th align="left" className={styles.storeTable_thead_visibility}>
							<SortableHeaderItem
								sortBy={STORE_ITEM_POSSIBLE_SORT.IsPublished}
								title="Visibility"
								handleSortChange={handleSortChange}
								sort={sort}
							/>
						</th>
						<th align="left" className={styles.storeTable_thead_actions} aria-label="Actions" />
					</tr>
				</thead>
				<tbody className={styles.storeTable_tbody}>
					{data?.pages.flatMap(page =>
						page?.records.map(item => (
							<tr
								key={item.id}
								className={cx({
									[styles.storeTable_tbody_suspended]:
										!item.isPublished ||
										!item.quantity ||
										moment(item.expirationDate).isBefore(moment()),
								})}
							>
								<td align="left" className={styles.storeTable_tbody_photo}>
									<ImageCell
										imagePath={(item?.mediaPathes as unknown as Array<string>)?.[0]}
										title={item.title}
										withPopup={true}
									/>
								</td>
								<td align="left" className={styles.storeTable_tbody_title}>
									<span title={item.title}>{item.title}</span>
								</td>
								<td align="left" className={styles.storeTable_tbody_price}>
									<span title={String(item.price)}>{item.price}</span>
								</td>
								<td align="left" className={styles.storeTable_tbody_createdDate}>
									<div className={styles.storeTable_tbody_createdDate_wrapper}>
										<ClockSvg />
										<span>{moment(item.createDate).format('MMMM DD, YYYY')}</span>
									</div>
								</td>
								<td align="left" className={styles.storeTable_tbody_expirationTime}>
									<div className={styles.storeTable_tbody_expirationTime_wrapper}>
										<ClockSvg />
										<span>
											{item.expirationDate
												? moment(item.expirationDate).format('MMMM DD, YYYY, hh:mm A')
												: 'No Expiration'}
										</span>
									</div>
								</td>
								<td align="left" className={styles.storeTable_tbody_couponsLeft}>
									<span title={String(item.quantity)}>{item.quantity}</span>
								</td>
								<td align="left" className={styles.storeTable_tbody_websiteLink}>
									<a
										href={item.productUrl}
										target="_blank"
										rel="noreferrer"
										aria-label={item.productUrl}
									>
										<CopySvg title={item.productUrl} width={24} height={24} />
									</a>
								</td>
								<td align="left" className={styles.storeTable_tbody_visibility}>
									<CustomTooltip title="Toggle visible\hidden Item" arrow={true} placement="top">
										{!item.isPublished ||
										!item.quantity ||
										moment(item.expirationDate).isBefore(moment()) ? (
											<PlaySVG
												width={24}
												height={24}
												onClick={() => handlePublishStoreItem(item)}
											/>
										) : (
											<PauseSVG
												width={24}
												height={24}
												onClick={() => handlePublishStoreItem(item)}
											/>
										)}
									</CustomTooltip>
								</td>
								<td align="left" className={styles.storeTable_tbody_actions}>
									<StoreItemActions storeItem={item} />
								</td>
							</tr>
						))
					)}
					<tr style={{ height: 0 }}>
						<td style={{ padding: 0 }}>
							<div ref={ref} style={{ height: '1px', visibility: 'hidden' }} />
						</td>
					</tr>
				</tbody>
			</table>

			{isFetchingNextPage || status === 'pending' ? <Loader /> : null}

			{!isFetching && !totalFetchedStatuses ? (
				<div className={styles.noItemsContainer}>
					<div className={styles.noItemsContainer__gradient} />
					<NoStatusImage className={styles.noItemsImage} />
					<div className={styles.noItemsTextContainer}>
						<span className={styles.noItemsTitle}>Oops</span>
						<span className={styles.noItemsText}>
							{status === 'error'
								? 'Something went wrong.'
								: 'There are currently no store items in the list. Create them to see them here.'}
						</span>
					</div>
				</div>
			) : null}

			<SnackbarSuccess
				open={isItemPublished}
				onClose={setIsItemPublished}
				message="Item has been published successfully!"
				width={274}
			/>

			<SnackbarSuccess
				open={isItemHidden}
				onClose={setIsItemHidden}
				message="Item has been hidden successfully!"
				width={274}
			/>

			<SnackbarError
				open={isMissingCoupons}
				onClose={setIsMissingCoupons}
				message="This item cannot be displayed because it has no available coupons."
				width={300}
				height={64}
			/>

			<SnackbarError
				open={isItemExpired}
				onClose={setIsItemExpired}
				message="This item has expired and cannot be made visible."
				width={336}
			/>

			<SnackbarError open={isApiError} onClose={setIsApiError} message="Something went wrong." />
		</section>
	);
};

export default StoreItemsTable;

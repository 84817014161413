import { FC, useState } from 'react';
// assets
import { ReactComponent as PencilSvg } from 'assets/image/pencil-light.svg';
// components
import PopupContainer from 'shared/components/PopupContainer';
import EditUserAccount from 'components/Organization/Volunteers/Table/UserActionCell/EditUserAccount';
import EditRolePopup from './EditRolePopup';
// styles
import styles from './EditUserCell.module.scss';

const EditUserCell: FC = () => {
	const [editRole, setEditRole] = useState(false);
	const [editProfile, setEditProfile] = useState(false);

	const editRoleCLickHandler = () => setEditRole(true);
	const editProfileCLickHandler = () => setEditProfile(true);

	const showEditRolePopupHandler = () => setEditRole(prev => !prev);
	const showEditProfilePopupHandler = () => setEditProfile(prev => !prev);

	return (
		<>
			<div className={styles.row}>
				<button className={styles.button} onClick={editRoleCLickHandler}>
					<PencilSvg className={styles.icon} />
					Edit role
				</button>
				<button className={styles.button} onClick={editProfileCLickHandler}>
					<PencilSvg className={styles.icon} />
					Edit profile
				</button>
			</div>
			{editRole && <EditRolePopup setIsShowPopup={showEditRolePopupHandler} />}
			{editProfile && (
				<PopupContainer setIsShowPopup={showEditProfilePopupHandler}>
					<EditUserAccount setIsShowPopup={showEditProfilePopupHandler} isDisabled />
				</PopupContainer>
			)}
		</>
	);
};

export default EditUserCell;

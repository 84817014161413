import { Tooltip, withStyles } from '@material-ui/core';
import TooltipArrow from '../../../assets/image/tooltip-arrow.svg';

const CustomTooltip = withStyles(() => ({
	tooltip: {
		backgroundColor: '#FFFFFF',
		color: '#2B243E',
		boxShadow: '0px 0px 20px 0px rgba(33, 75, 165, 0.15)',
		width: '170px',
		fontSize: '12px',
		fontWeight: 400,
		letterSpacing: '0.4px',
		borderRadius: '8px',
		margin: 'unset',
		marginBottom: '4px',
		padding: '10px',

		'& .MuiTooltip-arrow': {
			width: '34px',
			height: '9px',
			marginLeft: '12px !important',

			'&:before': {
				transform: 'unset',
				transformOrigin: 'unset',
				backgroundImage: `url(${TooltipArrow})`,
				backgroundColor: 'transparent',
				backgroundRepeat: 'no-repeat',
			},
		},
	},
}))(Tooltip);

export default CustomTooltip;

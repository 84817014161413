import { Menu, alpha, styled } from '@material-ui/core';
import styles from '../MarketingComponent/DailyQuotes/DailyQuotesTable/DailyQuotes.module.scss';
import React, { FC } from 'react';
import { MenuProps } from '@material-ui/core/Menu/Menu';

const StyledMenu = styled((props: MenuProps) => (
	<Menu
		elevation={0}
		anchorOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		transformOrigin={{
			vertical: 'top',
			horizontal: 'right',
		}}
		{...props}
	/>
))(({ theme }) => ({
	'& .MuiPaper-root': {
		border: '1px solid #C6CCFE',
		borderRadius: 8,
		minWidth: 140,
		boxShadow: '0px 0px 20px 0px rgba(33, 75, 165, 0.15);',
		'& .MuiMenu-list': {
			padding: 0,
		},
		'& .MuiMenuItem-root': {
			padding: '8px 12px',
			'&:hover, &:active': {
				backgroundColor: '#E4E7FF',
			},
		},
	},
}));

export default StyledMenu;

import React, { FC, useEffect, useRef } from 'react';
import { FieldInputProps } from 'formik';
import s from './index.module.scss';
import cx from 'classnames';

type InputTextProps = {
	placeholder?: string;
	isDisabled?: boolean;
	field: FieldInputProps<string>;
	id?: string;
	setFieldValue: (field: string, value: string) => void;
	fieldValue?: string;
	isTrimValue?: boolean;
	parentClassName?: string;
	type?: 'text' | 'number';
};

let timeout: NodeJS.Timeout;

const InputText: FC<InputTextProps> = ({
	placeholder,
	isDisabled,
	field,
	id,
	setFieldValue,
	fieldValue,
	isTrimValue,
	parentClassName,
	type = 'text',
}: InputTextProps) => {
	const ref = useRef<HTMLInputElement>(null);

	const setFocus = (): void => {
		if (ref && ref.current) {
			ref.current.focus();
		}
	};

	useEffect(() => {
		if (!!fieldValue && !!field && !!setFieldValue) {
			window.clearTimeout(timeout);
			timeout = setTimeout(() => {
				setFieldValue(field?.name, fieldValue.trim());
			}, 700);
		}
	}, [fieldValue, setFieldValue]);

	useEffect(() => {
		if (isTrimValue) setFieldValue(field.name, String(field.value)?.trim());
	}, [field.value, isTrimValue]);

	useEffect(() => {
		if (type === 'number') {
			ref.current?.addEventListener('keypress', e => {
				if (!['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'].includes(e.key)) e.preventDefault();
			});
		}
	}, [type]);

	return (
		<div className={cx(s.input__text, parentClassName)}>
			<input
				id={id && id}
				type={type}
				{...(type === 'number' ? { min: 0 } : {})}
				placeholder={placeholder || ''}
				{...field}
				ref={ref}
				disabled={isDisabled}
				value={fieldValue || field.value}
			/>
			{field && !!field.value && !isDisabled && <div className={s.input__text_edit} onClick={setFocus} />}
		</div>
	);
};

export default InputText;

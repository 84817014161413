import React, { FC, useMemo, useState } from 'react';
import { Field, FieldProps, Form, Formik, FormikProps } from 'formik';
import { isSameDay } from 'date-fns';
import cx from 'classnames';
// redux
import { useSelector } from 'react-redux';
import { selectorGetQuotesAll } from 'redux/dailyQuotes-service/selector';
import { createDailyQuote, deleteDailyQuote, editDailyQuote } from 'redux/dailyQuotes-service/actions';
import useThunkDispatch from 'core/customHooks/useThunkDispatch';
// components
import ButtonDefault from '../../../../Buttons/ButtonsDefault';
import InlineDatePicker from '../../../../Pickers/InlineDatePicker';
import InputText from '../../../../FormInputs/InputText';
import InputErrorHint from '../../../../InputErrorHint';
import Loader from '../../../../Loader';
// helpers
import { dailyQuoteValidationSchema } from 'core/validation';
// types
import { IQuoteRequest, IQuoteResponse, QuoteRequest } from '@joc/api-gateway';
// styles
import styles from './AddQuoteTab.module.scss';

interface IAddQuoteTabProps {
	quote?: IQuoteResponse;
	onClose: () => void;
}

const MAX_MESSAGE_LENGTH = 80;

export const AddQuoteTab: FC<IAddQuoteTabProps> = ({ quote, onClose }) => {
	const dispatch = useThunkDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const dailyQuotes = useSelector(selectorGetQuotesAll);

	const initialData = useMemo(
		() => ({
			message: quote?.message || '',
			startDate: quote?.startDate || new Date(),
		}),
		[]
	);

	const submitClickHandler = async (value: IQuoteRequest) => {
		const { message, startDate } = value;
		setIsLoading(true);

		if (quote) {
			await dispatch(editDailyQuote(quote.id, value as QuoteRequest));
		} else {
			const quotesWithSameDate = dailyQuotes.filter(q => isSameDay(q.startDate, startDate));

			await Promise.all(quotesWithSameDate.map(async q => dispatch(deleteDailyQuote(q))));

			await dispatch(createDailyQuote([{ message: message.trim(), startDate }] as Array<QuoteRequest>));
		}

		setIsLoading(false);

		onClose();
	};

	return (
		<Formik
			initialValues={initialData}
			validationSchema={dailyQuoteValidationSchema}
			onSubmit={submitClickHandler}
			className={styles.form}
		>
			{({ setFieldValue, dirty, isValid, errors, touched }: FormikProps<IQuoteRequest>) => (
				<Form className={styles.form}>
					<Field name="message">
						{({ field }: FieldProps<string, IQuoteRequest>) => (
							<div className={styles.form_item}>
								<label className={styles.form_label} htmlFor="message">
									Daily Quotes text
									<span className={styles.form_required}>*</span>
								</label>
								<InputText
									id="message"
									field={field}
									placeholder="Your Quote"
									setFieldValue={setFieldValue}
								/>

								{(errors.message && touched.message && (
									<InputErrorHint errorText={errors.message} disableMargin={true} />
								)) || (
									<span
										className={cx(styles.form_limit, {
											[styles.form_limit_error]: field.value.length > MAX_MESSAGE_LENGTH,
										})}
									>
										Maximum characters: {field.value.length}/{MAX_MESSAGE_LENGTH}
									</span>
								)}
							</div>
						)}
					</Field>
					<div className={styles.form_pickers_date}>
						<Field name="startDate">
							{({ field }: FieldProps) => (
								<div className={styles.form_item}>
									<label className={styles.form_label} htmlFor="startDay">
										Start Date<span className={styles.form_required}>*</span>
									</label>
									<InlineDatePicker
										parentClassNames={styles.form_pickers_date__item}
										fieldName={field.name}
										fieldValue={field.value}
										setFieldValue={setFieldValue}
										availableFuture={true}
									/>
									{errors.startDate && touched.startDate && (
										<InputErrorHint errorText={errors.startDate as string} />
									)}
								</div>
							)}
						</Field>
					</div>
					<div className={styles.form_footer}>
						<ButtonDefault
							classList={['secondary', 'lg']}
							parentClassName={styles.footer_button}
							title="Cancel"
							clickHandler={onClose}
						/>
						{isLoading ? (
							<Loader loadProps={{ disableCenterStyle: true, disableMargin: true }} />
						) : (
							<ButtonDefault
								submitType={true}
								classList={['primary', 'lg']}
								parentClassName={cx(styles.footer_button, {
									[styles.disable_submit]: !dirty || !isValid,
								})}
								title={quote ? 'Save' : 'Create'}
								disabled={!dirty || !isValid}
							/>
						)}
					</div>
				</Form>
			)}
		</Formik>
	);
};

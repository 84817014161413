import React, { Dispatch, FC, SetStateAction } from 'react';
import { Tab, TabProps, Tabs, withStyles } from '@material-ui/core';
import { createStyles } from '@material-ui/core/styles';

const CustomTabs = withStyles({
	root: {
		minHeight: 44,
		maxHeight: 44,
	},
	flexContainer: {
		height: '100%',
	},
	indicator: {
		backgroundColor: '#214ba5',
	},
})(Tabs);

const CustomTab = withStyles(() =>
	createStyles({
		root: {
			minHeight: 44,
			textTransform: 'none',
			color: '#5B768B',
			fontFamily: 'Rubik',
			fontSize: '18px',
			fontWeight: 400,
			lineHeight: '131%',
			borderBottom: '2px solid #C6CCFE',
			padding: '10px 16px',
		},
		selected: {
			color: '#214ba5 !important',
			fontWeight: 500,
		},
	})
)((props: TabProps) => <Tab disableRipple={true} {...props} />);

export enum CouponsTabs {
	CouponsList,
	AddCoupons,
}

type StoreCouponsTabsProps = {
	currentTab?: CouponsTabs;
	setCurrentTab: Dispatch<SetStateAction<CouponsTabs>>;
};

const StoreCouponsTabs: FC<StoreCouponsTabsProps> = ({ currentTab, setCurrentTab }) => {
	const handleChange = (event: React.ChangeEvent<{}>, newValue: CouponsTabs) => {
		setCurrentTab(newValue);
	};

	return (
		<CustomTabs
			value={currentTab}
			indicatorColor="primary"
			textColor="primary"
			onChange={handleChange}
			variant="fullWidth"
			aria-label="disabled tabs example"
		>
			<CustomTab label="Coupons" tabIndex={CouponsTabs.CouponsList} />
			<CustomTab label="Add Coupons" tabIndex={CouponsTabs.AddCoupons} />
		</CustomTabs>
	);
};

export default StoreCouponsTabs;

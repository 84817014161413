import React, { FC, useState } from 'react';
import cx from 'classnames';
import { createTheme, Slider, ThemeProvider } from '@material-ui/core';
import { Overrides } from '@material-ui/core/styles/overrides';
import styles from './index.module.scss';

interface SliderRangeProps {
	min: number;
	max: number;
	fieldValue?: number;
	fieldName: string;
	setFieldValue: (field: string, value: number, shouldValidate?: boolean | undefined) => void;
}

const materialTheme = createTheme({
	overrides: {
		MuiSlider: {
			root: {
				color: '#214ba5',
			},
		},
	} as Overrides,
});

const SliderRange: FC<SliderRangeProps> = ({ min, max, fieldValue, fieldName, setFieldValue }: SliderRangeProps) => {
	const [isMaxValue, setIsMaxValue] = useState(false);

	return (
		<div className={styles.main}>
			<ThemeProvider theme={materialTheme}>
				<Slider
					min={min}
					max={max}
					value={fieldValue}
					onChange={(e, value) => {
						if (value === max) setIsMaxValue(true);
						setIsMaxValue(false);
						setFieldValue(fieldName, value as number);
					}}
					classes={{
						root: styles.slider,
						rail: styles.slider__rail,
						thumb: cx(styles.slider__thumb, isMaxValue && styles.slider__thumb_max),
						track: styles.slider__track,
					}}
				/>
			</ThemeProvider>
			<span className={styles.slider__value}>{fieldValue}</span>
		</div>
	);
};

export default SliderRange;

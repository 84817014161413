import React, { FC, SyntheticEvent } from 'react';
import cx from 'classnames';
import { Snackbar } from '@material-ui/core';

// icons
import { ReactComponent as ErrorIcon } from 'assets/image/error-icon.svg';

// types
import { SnackbarProps } from './SnackbarsTypes';
import { SnackbarCloseReason } from '@material-ui/core/Snackbar/Snackbar';

// styles
import styles from './Snackbars.module.scss';

const SnackbarError: FC<SnackbarProps> = ({
	open,
	onClose,
	message,
	title = 'Error',
	duration = 3000,
	width,
	height,
}) => {
	const handleClose = (_event: SyntheticEvent<any>, reason?: SnackbarCloseReason) => {
		if (reason === 'clickaway') {
			return;
		}

		onClose(!open);
	};

	return (
		<Snackbar open={open} autoHideDuration={duration} onClose={handleClose}>
			<div className={cx(styles.snackbar, styles.snackbar__error)} style={{ width, height }}>
				<ErrorIcon width={24} height={24} />
				<div className={styles.snackbar__error__content}>
					<span className={styles.snackbar__error__content_title}>{title}</span>
					<span className={styles.snackbar__error__content_message}>{message}</span>
				</div>
			</div>
		</Snackbar>
	);
};

export default SnackbarError;
